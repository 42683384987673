import React, { useRef, useCallback } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Scrollspy from "react-scrollspy";
import { Link as ScrollLink } from "react-scroll";
import AutoAffix from "../../components/affix/AutoAffix";
import Layout from "../../components/layout";
import Seo from "../../components/seo";

import FormContatti from "../../components/form-contatti";

import Square from "../../svg/square-det.svg";
import SquareImgDx from "../../svg/square-img-dx.svg";
import SquareImgSx from "../../svg/square-img-sx.svg";

const hero = [
  {
    title: "Drupal",
    description: "La piattaforma CMS performante, sicura ed efficiente",
  },
];
const breadcrumb = [
  { title: "Home", link: "/" },
  { title: "Competence center", link: "/competence-center/" },
  { title: "Drupal" },
];

const Drupal = ({ data, location }) => {
  const { intro, design, infrastruttura, infrastruttura2 } = data;
  const scrollRef = useRef();
  const getScrollContainer = useCallback(() => scrollRef.current, []);

  return (
    <Layout location={location} hero={hero} breadcrumb={breadcrumb}>
      <Seo title={`Drupal`} description={`La piattaforma CMS performante, sicura ed efficiente`} />

      <div className="section section--blue-light-2">
        <Square className="square d-none d-md-block" />
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
              <p>
                Nel Competence Center Drupal, Ariadne raccoglie un insieme di professionisti con
                esperienza consolidata sullo storico <strong>CMS PHP/MySQL</strong>. L'obiettivo è
                offrire un servizio altamente qualificato alle aziende e agli enti che hanno scelto
                Drupal come piattaforma per avviare nuove iniziative o che necessitano di una
                gestione professionale e affidabile di progetti esistenti.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 offset-md-4 col-xl-7 offset-xl-5 mb-0 mb-xl-5">
              <div className="section__img-intro">
                <SquareImgDx className="square-top" />
                <SquareImgSx className="square-bottom" />
                <GatsbyImage image={getImage(intro)} alt="Image intro" className="w-100" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-xl-4 d-none d-md-block">
              <AutoAffix
                affixClassName="sidebar sidebar--affix"
                topClassName="sidebar sidebar--affix-top"
                bottomClassName="sidebar sidebar--affix-bottom"
                viewportOffsetTop={60}
                container={getScrollContainer}
              >
                <div>
                  <nav className="anchor-nav">
                    <Scrollspy
                      items={[
                        "perche-drupal",
                        "linee-guida-agid",
                        "progettazione-user-centered",
                        "versione-migliore",
                        "moduli-drupal",
                        "vantaggi-tecnici",
                        "drupal-ariadne",
                        "modalita-di-erogazione",
                      ]}
                      offset={-60}
                      className="anchor-nav__list"
                      currentClassName="anchor-nav__item--active"
                    >
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#perche-drupal`}
                          to={`perche-drupal`}
                          className="anchor-nav__link"
                          title="Perché Drupal"
                        >
                          Perché Drupal
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#linee-guida-agid`}
                          to={`linee-guida-agid`}
                          className="anchor-nav__link"
                          title="Le linee guida AgID"
                        >
                          Le linee guida AgID
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#progettazione-user-centered`}
                          to={`progettazione-user-centered`}
                          className="anchor-nav__link"
                          title="Progettazione User Centered, accessibilità e usabilità"
                        >
                          Progettazione User Centered, accessibilità e usabilità
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#versione-migliore`}
                          to={`versione-migliore`}
                          className="anchor-nav__link"
                          title="Qual è la versione migliore? Drupal 9 & 10"
                        >
                          Qual è la versione migliore?
                          <br />
                          Drupal 9 & 10
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#moduli-drupal`}
                          to={`moduli-drupal`}
                          className="anchor-nav__link"
                          title="Moduli su Drupal: personalizzazione e sviluppo"
                        >
                          Moduli su Drupal:
                          <br />
                          personalizzazione e sviluppo
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#vantaggi-tecnici`}
                          to={`vantaggi-tecnici`}
                          className="anchor-nav__link"
                          title="Vantaggi tecnici"
                        >
                          Vantaggi tecnici
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#drupal-ariadne`}
                          to={`drupal-ariadne`}
                          className="anchor-nav__link"
                          title="I professionisti del Competence Center Drupal di Ariadne"
                        >
                          I professionisti del Competence Center Drupal di Ariadne
                        </ScrollLink>
                      </li>
                      <li className="anchor-nav__item">
                        <ScrollLink
                          href={`#modalita-di-erogazione`}
                          to={`modalita-di-erogazione`}
                          className="anchor-nav__link"
                          title="Modalità di Erogazione del Sito"
                        >
                          Modalità di Erogazione del Sito
                        </ScrollLink>
                      </li>
                    </Scrollspy>
                  </nav>
                </div>
              </AutoAffix>
            </div>
            <div className="col-md-8 offset-md-1 col-xl-7 offset-xl-1 mb-5">
              <div className="anchor-text" ref={scrollRef}>
                <div id="perche-drupal" className="anchor">
                  <h2 className="anchor-text__title">Perché Drupal</h2>
                  <p>
                    Drupal è una <strong>piattaforma CMS</strong> con un'ampia diffusione negli USA
                    e in Europa, dove è stata scelta da aziende come{" "}
                    <a
                      href="http://www.ibm.com"
                      target="_blank"
                      title="IBM"
                      rel="noopener noreferrer"
                    >
                      IBM
                    </a>
                    ,{" "}
                    <a
                      href="http://www.nestle.com"
                      target="_blank"
                      title="Nestlè"
                      rel="noopener noreferrer"
                    >
                      Nestlè
                    </a>{" "}
                    ed{" "}
                    <a
                      href="http://www.engie.com"
                      target="_blank"
                      title="ENGIE"
                      rel="noopener noreferrer"
                    >
                      ENGIE
                    </a>
                    .
                    <br />
                    In Italia, questo strumento è molto presente nel contesto universitario ed è
                    promosso da <strong>AgID</strong> (Agenzia per l'Italia digitale) come
                    piattaforma di riferimento per la creazione di siti della Pubblica
                    Amministrazione, sia Locale che Centrale.
                  </p>
                  <p>
                    Il principale CMS alternativo a Drupal è WordPress, con il quale condivide
                    alcune caratteristiche: entrambi infatti sono <strong>open source</strong>,
                    utilizzano una <strong>tecnologia PHP/MySQL</strong> e sono presenti sul mercato
                    da oltre vent'anni. Le quote di mercato però, risultano molto diverse, a
                    vantaggio di Wordpress.
                    <br />
                    Perché quindi la nostra scelta ricade su Drupal?
                  </p>
                  <p>
                    In Ariadne abbiamo sempre realizzato{" "}
                    <strong>sistemi di classe enterprise</strong> e{" "}
                    <strong>mission critical</strong>, con la massima attenzione verso questi
                    aspetti:
                  </p>
                  <ul>
                    <li>sicurezza</li>
                    <li>estendibilità</li>
                    <li>manutenibilità</li>
                    <li>prestazioni</li>
                    <li>redazioni complesse</li>
                    <li>multisite</li>
                    <li>integrazioni</li>
                  </ul>
                  <p>
                    A fronte dell'uguale complessità e costo dei due strumenti, abbiamo scelto,
                    quindi, quello che eccelle sotto tutti questi punti di vista. Infatti,{" "}
                    <strong>
                      Drupal ci permette di costruire la soluzione più adatta per i bisogni dei
                      nostri clienti
                    </strong>
                    , senza tralasciare un'erogazione sul cloud dei website sicura, performante,
                    efficiente nel tempo.
                  </p>
                </div>
                <div id="linee-guida-agid" className="anchor">
                  <h2 className="anchor-text__title">Le linee guida AgID</h2>
                  <p>
                    Le linee guida di AgiD - adottate con la Determinazione n. 255/2021 - hanno lo
                    scopo di{" "}
                    <strong>
                      definire e orientare la progettazione e la realizzazione dei servizi digitali
                      erogati dalle amministrazioni
                    </strong>
                    , secondo quanto definito dagli articoli 7, 53 e 54 del D.Lgs. 7 marzo 2005, n.
                    82 e s.m.i. recante il Codice dell'Amministrazione Digitale (CAD).
                  </p>
                  <p>
                    Con il Competence Center Drupal, Ariadne garantisce un livello di eccellenza
                    nella realizzazione di portali e applicazioni che, utilizzando questo CMS,
                    vengono progettati in modo da essere responsive e accessibili, conformi alle
                    leggi vigenti.
                  </p>
                </div>
                <div id="progettazione-user-centered" className="anchor">
                  <h2 className="anchor-text__title">
                    Progettazione User Centered, accessibilità e usabilità
                  </h2>
                  <p>
                    Per Ariadne, <strong>design</strong> significa progettare soluzioni digitali con
                    un <strong>approccio human centered</strong>, mettendo al centro i bisogni degli
                    utenti che utilizzeranno il sistema.
                  </p>
                  <p>
                    Per raggiungere questo scopo adottiamo un approccio alla risoluzione dei
                    problemi basato sul{" "}
                    <a
                      href="https://www.ariadnedigital.it/ux-design/"
                      target="_blank"
                      title="Design Thinking"
                      rel="noopener noreferrer"
                    >
                      Design Thinking
                    </a>{" "}
                    che include l'
                    <strong>
                      attenzione ai bisogni degli utenti finali, la collaborazione di team
                      multidisciplinari
                    </strong>{" "}
                    e la <strong>prototipazione veloce</strong>. Il nostro processo è iterativo e si
                    basa sulle best practice definite da{" "}
                    <a
                      href="https://www.nngroup.com/"
                      target="_blank"
                      title="Nielsen Norman Group"
                      rel="noopener noreferrer"
                    >
                      Nielsen Norman Group
                    </a>{" "}
                    e{" "}
                    <a
                      href="https://designthinking.ideo.com/"
                      target="_blank"
                      title="IDEO"
                      rel="noopener noreferrer"
                    >
                      IDEO
                    </a>
                    , sulle quali vantiamo team certificati.
                  </p>
                  <GatsbyImage
                    image={getImage(design)}
                    alt="Salesforce Offerta"
                    className="anchor-text__img"
                  />
                  <p>
                    Quando parliamo di <strong>usabilità</strong> non pensiamo alla sola fase di
                    progettazione e coding, ma anche a tutti i passaggi di test e nel corso della
                    vita del website.
                    <br />
                    Effettuiamo i <strong>test di usabilità</strong> secondo il Protocollo eGLU LG
                    per la realizzazione di test cosí come definito da{" "}
                    <a
                      href="https://docs.italia.it/italia/designers-italia/manuale-operativo-design-docs/it/versione-corrente/doc/design-research/test-usabilita.html"
                      target="_blank"
                      title="AgID"
                      rel="noopener noreferrer"
                    >
                      AgID
                    </a>
                    .
                  </p>
                  <p>
                    Parallelamente alle verifiche di usabilità prevediamo un{" "}
                    <strong>monitoraggio dell'accessibilità</strong> del sito attraverso software di
                    validazione in modo da verificare il mantenimento dei requisiti{" "}
                    <strong>anche a seguito dell'attività redazionale</strong>.
                  </p>
                </div>
                <div id="versione-migliore" className="anchor">
                  <h2 className="anchor-text__title">
                    Qual è la versione migliore?
                    <br />
                    Drupal 9 & 10
                  </h2>
                  <p>
                    Pur avendo ampiamente utilizzato le versioni Drupal precedenti alla 9, ad oggi
                    Ariadne propone l'adozione di Drupal versione 9 o 10, da decidere al momento
                    dello start del progetto.
                  </p>
                  <p>
                    La <strong>versione 10</strong>, rilasciata a dicembre 2022, presenta
                    interessanti aggiornamenti, tra cui:
                  </p>
                  <ul>
                    <li>
                      <strong>Nuovo tema di amministrazione Claro</strong> (in sostituzione di
                      Seven) per una maggiore usabilità;
                    </li>
                    <li>
                      <strong>Editor CKEditor 5</strong>: miglior esperienza di authoring e editing
                      più moderno (in sostituzione di CKEditor 4);
                    </li>
                    <li>
                      <strong>Potenziamento della funzionalità dei moduli Layout Builder</strong> e
                      della gestione dei Media;
                    </li>
                    <li>
                      Possibilità di <strong>aggiornamenti automatici</strong> di core e moduli.
                    </li>
                  </ul>
                </div>
                <div id="moduli-drupal" className="anchor">
                  <h2 className="anchor-text__title">
                    Moduli su Drupal:
                    <br />
                    personalizzazione e sviluppo
                  </h2>
                  <p>
                    Drupal prevede una vasta lista di moduli contenuti nel core, attivabili o
                    disattivabili a seconda delle esigenze e dei requisiti software.
                    <br />
                    Tutti i moduli installati nel core di Drupal in aggiunta a quelli disponibili,
                    sviluppati dalla comunità, sono definiti <strong>
                      moduli contributivi
                    </strong>{" "}
                    (contrib) e sono valutati e scelti dal team Ariadne in base alla loro ultima
                    versione stabile e alla loro compatibilità con l'ultima versione del CMS.
                    <br />
                    Prima dell'adozione dei moduli contributivi valutiamo sempre il loro{" "}
                    <strong>
                      stato di attività di manutenzione evolutiva e fixing progressivi
                    </strong>{" "}
                    da parte della comunità di sviluppatori.
                    <br />
                    In caso di sviluppo di <strong>moduli custom</strong>, rispettiamo rigorosamente
                    le linee guida e gli standard di sicurezza previsti dalla comunità Drupal anche
                    in termini di configurazioni, pattern di programmazione, strutturazione delle
                    cartelle su file system, aggiornamenti progressivi, listing degli stessi
                    all'interno delle estensioni CMS, funzionalità di back-office e pagine custom in
                    aggiunta alle disponibili.
                  </p>
                </div>
                <div id="vantaggi-tecnici" className="anchor">
                  <h2 className="anchor-text__title">Vantaggi tecnici</h2>
                  <p>
                    <strong>Performance</strong>
                  </p>
                  <ul>
                    <li>Ottimizzazione della cache</li>
                    <li>Modulo CDN</li>
                    <li>Lazy Load</li>
                    <li>Ottimizzazione immagini</li>
                    <li>Ottimizzazione della banda</li>
                  </ul>
                  <p>
                    <strong>Cloud Native Ready</strong>
                  </p>
                  <p>
                    Piattaforma moderna e pronta per gli approcci di sviluppo Cloud Native. Consente
                    inoltre di configurare pipeline di Continuous Integration e Continuous Delivery
                    ed automatizzare molti dei processi manuali come la QA e il testing automatico.
                  </p>
                  <p>
                    <strong>Affidabile, Diffuso e Sicuro</strong>
                  </p>
                  <ul>
                    <li>è nella top 3 dei CMS più usati al mondo</li>
                    <li>esiste da oltre 20 anni</li>
                    <li>è in continuo aggiornamento senza mai perdere in stabilità</li>
                    <li>garantisce supporto alle versioni precedenti per almeno 5 anni</li>
                    <li>
                      sicuro “by design” poiché rispecchia tutti i criteri di sicurezza stabiliti
                      dall'Open Web Application Security Project (OWASP).
                    </li>
                  </ul>
                  <p>
                    <strong>API-First</strong>
                  </p>
                  <ul>
                    <li>
                      Una delle caratteristiche più interessati di Drupal e della sua filosofia
                      progettuale è relativa alle API. L'architettura di Drupal può essere decoupled
                      per operare in modalità “headless”. Così facendo è possibile ad esempio avere
                      un backend che espone delle API (in questo caso Drupal) e un front-end
                      separato.
                    </li>
                  </ul>
                </div>
                <div id="drupal-ariadne" className="anchor">
                  <h2 className="anchor-text__title">
                    I professionisti del Competence Center Drupal di Ariadne
                  </h2>
                  <p>
                    Come per altre tecnologie, il Competence Center Drupal di Ariadne può contare su
                    figure che, oltre che essere specializzate nella loro area specifica (dalla
                    progettazione UX Design fino alla gestione delle infrastrutture Cloud),
                    conoscono in modo approfondito la piattaforma e sono in grado di rispondere in
                    modo competente ed efficace alle esigenze dei clienti.
                  </p>
                  <p>
                    Il Competence Center Drupal annovera professionisti che coprono tutti gli
                    aspetti di un progetto web complesso:
                  </p>
                  <ul>
                    <li>
                      <strong>Project Manager</strong>;
                    </li>
                    <li>
                      <strong>User Experience Designer</strong>, con competenze specifiche sulla
                      progettazione in Drupal e sui temi di accessibilità a norme AgID;
                    </li>
                    <li>
                      <strong>System Architect</strong> in grado di progettare architetture
                      multi-tenant, integrazioni complesse con sistemi di back-end, architetture in
                      SSO o basate su IDP pubblici o privati;
                    </li>
                    <li>
                      <strong>Sviluppatori</strong> di Front-End e di Back-end con esperienza
                      pluriennale sulla tecnologia, in particolare da Drupal 8 in poi;
                    </li>
                    <li>
                      <strong>Formatori e operatori di helpdesk</strong> con conoscenza del CMS
                      Drupal che affiancano il personale del Cliente nella fase di avvio, di
                      redazione/porting dei contenuti e nel supporto on-going.
                    </li>
                  </ul>
                  <p>
                    Si evidenzia come il Competence Center Drupal di Ariadne sia formato da
                    personale con esperienza lavorativa nel ruolo di diversi anni e con una
                    importante anzianità aziendale.
                  </p>
                </div>
                <div id="modalita-di-erogazione" className="anchor">
                  <h2 className="anchor-text__title">Modalità di Erogazione del Sito</h2>
                  <p>
                    L'esperienza nella gestione di Infrastrutture semplici e complesse in ambito
                    on-premise e cloud ci permette di offrire e/o supportare l'ente nella scelta
                    migliore in termini di infrastruttura e di poter erogare il servizio anche in
                    totale trasparenza per l'ente in modalità SAAS nel rispetto dei requisiti AGID.
                  </p>
                  <p>Di seguito un esempio di Infrastruttura in CLOUD possibile:</p>
                  <GatsbyImage
                    image={getImage(infrastruttura)}
                    alt="Salesforce Offerta"
                    className="anchor-text__img"
                  />
                  <GatsbyImage
                    image={getImage(infrastruttura2)}
                    alt="Salesforce Offerta"
                    className="anchor-text__img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormContatti location={location} />
    </Layout>
  );
};

export default Drupal;

export const query = graphql`
  query {
    intro: file(relativePath: { eq: "pages/drupal.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    design: file(relativePath: { eq: "pages/drupal-design.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    infrastruttura: file(relativePath: { eq: "pages/drupal-infrastruttura.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
    infrastruttura2: file(relativePath: { eq: "pages/drupal-infrastruttura-2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 700, layout: CONSTRAINED)
      }
    }
  }
`;
